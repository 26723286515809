/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Scroll
 * Type: Generic
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass-rem/rem";
@use "../settings/spacings";

/*******************************************************************************
 * Default styles
*******************************************************************************/

[id] {
  scroll-margin-block-start: 0;
}
