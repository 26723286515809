/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Blocks
 * Type: Object
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass-rem/rem";
@use "../settings/spacings";
@use "../settings/colors";
@use "../tools/global";

/*******************************************************************************
 * Default styles
*******************************************************************************/

.o-blocks {
  position: relative;
  inline-size: 100%;
}

  .o-blocks__item {
    position: relative;
    z-index: 1;
    min-block-size: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 4rem calc(1rem + 5vw);

    > * {
      inline-size: 100%;
      max-inline-size: 30rem;
      margin-inline: auto;
    }
  }

@keyframes block-reveal {
  0% {
    transform: translateY(4rem);
  }
  10% {
    transform: translateY(4rem);
  }
  40% {
    transform: translateY(0);
  }
  60% {
    transform: scale(1);
  }
  90% {
    transform: translateY(-4rem);
  }
  100% {
    transform: translateY(-4rem);
  }
}

/*******************************************************************************
 * Print
*******************************************************************************/

@media print {
  .o-blocks__item {
    min-block-size: auto;
    padding: 4rem 2rem;
  }
}
