/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Title
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "../tools/typography" as typo;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-title {
  @include typo.title("title");

  position: relative;
  margin: 0;
}

/*******************************************************************************
 * Print
*******************************************************************************/

@media print {
  .c-title {
    break-inside: avoid;
  }
}
