/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Scene
 * Type: Component
**/

/*******************************************************************************
 * Utilities
*******************************************************************************/

@use "../settings/colors";
@use "../settings/fonts";
@use "@lunaweb/vega-reactor-scss/src/tools/animation";

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-scene {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 4rem calc(1rem + 5vw);
  gap: calc(1rem + 2vw);

  &--viewport {
    min-block-size: 100vh;
  }
}

  .c-scene__main {
    position: relative;
    z-index: 1;
    flex-basis: 0;
    flex-grow: 999;
    min-inline-size: 65%;
  }

    .c-scene__heading {
      max-inline-size: 30rem;
      margin-inline: auto;
      transition: transform calc(1s * var(--animations-enabled)) ease-in-out;
      will-change: transform;

      @starting-style {
        transform: translateY(1rem);
      }

      &::after {
        content: "";
        position: absolute;
        inset: 0;
        display: block;
        background-color: colors.get("background");
        transform: scaleY(0);
        transform-origin: center 100%;
        transition: transform calc(1s * var(--animations-enabled)) ease-in-out;
        will-change: transform;

        @starting-style {
          transform: scaleY(1);
        }
      }

      > * {
        margin-block: 0;

        + * {
          margin-block-start: 1.5em;
        }
      }
    }

  .c-scene__aside {
    flex-grow: 1;
    flex-basis: 10rem;
    font-size: max(10rem, 25vw);
    text-align: center;
    line-height: 0;
  }

/*******************************************************************************
 * Print
*******************************************************************************/

@media print {
  .c-scene--viewport {
    min-block-size: auto;
    padding: 4rem 2rem;
  }
}
