/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Timeline
 * Type: Component
**/

/*******************************************************************************
 * Utilities
*******************************************************************************/

@use "../settings/colors";

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-timeline {
  position: relative;
}

  .c-timeline__items {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    padding-inline-start: 2rem;
    list-style: none;
  }

    .c-timeline__item {
      position: relative;
      padding-block: 1rem;

      &::before {
        content: "";
        position: absolute;
        inset-block-start: 2.25rem;
        inset-inline-start: -2rem;
        inline-size: 0.125em;
        block-size: calc(100% - 0.5em);
        background-color: currentcolor;
        transform: translate(-50%, 0);
        border-radius: 1em;

        @supports(animation-timeline: view(block)) {
          animation-name: reveal-line;
          animation-fill-mode: both;
          animation-timeline: view(block);
          animation-range: entry exit;
          animation-duration: calc(var(--animations-enabled) * 1ms); /* Firefox requires this to apply the animation */
        }
      }

      &::after {
        content: "";
        position: absolute;
        inset-block-start: 2rem;
        inset-inline-start: -2rem;
        inline-size: 0.125em;
        block-size: 0.125em;
        background-color: currentcolor;
        border-radius: 50%;
        transform: translate(-50%, -50%);

        @supports(animation-timeline: view(block)) {
          animation-name: reveal-line;
          animation-fill-mode: both;
          animation-timeline: view(block);
          animation-range: entry exit;
          animation-duration: calc(var(--animations-enabled) * 1ms); /* Firefox requires this to apply the animation */
        }
      }

      > * {
        margin-block: 0;

        + * {
          margin-block-start: 0.25em;
        }
      }

      &:last-child {
        &::before {
          block-size: auto;
          inset-block-end: 1rem;
        }
      }
    }

      .c-timeline__title {}

      .c-timeline__subtitle {
        font-size: 0.75em;
        font-style: italic;
      }

      .c-timeline__description {}

@keyframes reveal-line {
  0% {
    transform-origin: top center;
    transform: translateY(calc(var(--animations-enabled) * 10rem)) scale(calc(1 - var(--animations-enabled) * 0.5), calc(1 + var(--animations-enabled) * 5));
  }
  30% {
    transform-origin: top center;
    transform: translateY(0) rotateX(0) scale(1);
  }
  70% {
    transform-origin: bottom center;
    transform: translateY(0) rotateX(0) scale(1);
  }
  100% {
    transform-origin: bottom center;
    transform: translateY(calc(var(--animations-enabled) * -10rem)) scale(calc(1 - var(--animations-enabled) * 0.5), calc(1 + var(--animations-enabled) * 5));
  }
}

/*******************************************************************************
 * Print
*******************************************************************************/

@media print {
  .c-timeline__item {
    break-inside: avoid;

    &::before,
    &::after {
      animation: none;
    }
  }
}
