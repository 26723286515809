/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Navigation
 * Type: Component
**/

/*******************************************************************************
 * Utilities
*******************************************************************************/

@use "sass-rem/rem";
@use "../settings/colors";

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-navigation {
  padding: 0.5rem 1em;
  font-size: 0.85rem;
}

  .c-navigation__items {
    display: flex;
    justify-content: center;
    gap: 2rem;
    list-style: none;
    margin: 0;
  }

    .c-navigation__item {}

      .c-navigation__link {
        font-size: 1em;
      }
