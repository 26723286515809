/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Text
 * Type: Utilities
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass-rem/rem";
@use "../settings/fonts";
@use "../tools/typography" as typo;

/*******************************************************************************
 * Base
*******************************************************************************/

// .u-text-start {
//   text-align: start;
// }

.u-text-center {
  text-align: center;
}

// .u-text-end {
//   text-align: end;
// }

// .u-text-inherit {
//   text-align: inherit;
// }
