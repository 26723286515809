/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Photon
 * Type: Component
**/

/*******************************************************************************
 * Utilties
*******************************************************************************/

@use "sass-em/em";
@use "sass-rem/rem";
@use "@lunaweb/vega-reactor-scss/src/tools/animation";

/*******************************************************************************
 * Variables
*******************************************************************************/

$photon-size: 2px;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-photon {
  flex-direction: row;
  justify-content: flex-start;
  position: relative;
  display: inline-flex;
  padding: 0 1em;
  font-size: rem.convert($photon-size);
  gap: 1em;

  &::before,
  &::after {
    content: "";
    display: inline-block;
    block-size: 1em;
    border-radius: 1em;
    background-color: currentColor;
    will-change: inline-size, transform;
  }

  &::before {
    flex: 0 0 auto;
    inline-size: 0;
  }

  &::after {
    flex: 0 1 auto;
    inline-size: 0;
  }

  &:not(.is-mounted) {
    &::before {
      inline-size: 1em;
      animation: photon-particle-in animation.$transition-duration * 0.8;
    }
    &::after {
      inline-size: 10em;
      animation: photon-trail-in animation.$transition-duration;
    }
  }

  &.is-mounted {
    &.is-entering {
      &::before {
        animation: photon-particle-in animation.$transition-duration * 0.8;
      }

      &::after {
        animation: photon-trail-in animation.$transition-duration;
      }

      &:not(.has-photon) {
        justify-content: flex-end;
      }
    }

    &.is-idle,
    &.has-photon {
      &::before {
        inline-size: 1em;
      }
    }

    &.is-idle {
      &::before {
        animation: photon-particle-idle animation.$transition-duration * 5 infinite ease-out;
      }
      &::after {
        inline-size: 10em;
        transform-origin: left center;
        animation: photon-trail-idle animation.$transition-duration * 5 infinite ease-out;
      }
    }

    &.is-leaving {
      &::before {
        animation: photon-particle-out animation.$transition-duration;
      }

      &::after {
        animation: photon-trail-out animation.$transition-duration;
      }
    }
  }

  &--reversed {
    flex-direction: row-reverse;

    &.is-mounted {
      &.is-idle {
        &::before {
          animation-name: photon-particle-idle-reversed;
        }
        &::after {
          transform-origin: right center;
        }
      }
    }
  }
}

@keyframes photon-particle-in {
  0% {
    inline-size: 0;
  }
  50% {
    inline-size: calc(100% - 2em);
  }
  100% {
    inline-size: 1em;
  }
}

@keyframes photon-trail-in {
  0% {
    inline-size: 1em;
  }
  40% {
    inline-size: 1em;
  }
  80% {
    inline-size: calc(100% - 2em);
  }
  100% {
    inline-size: 10em;
  }
}

@keyframes photon-particle-idle {
  0% {
    transform: translate(0, 0);
  }
  90% {
    transform: translate(em.convert(-1px, $photon-size), 0) scale(1.125, 0.85);
  }
  91% {
    transform: translate(em.convert(-1px, $photon-size), 0) scale(1.125, 0.85);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes photon-particle-idle-reversed {
  0% {
    transform: translate(0, 0);
  }
  90% {
    transform: translate(em.convert(1px, $photon-size), 0) scale(1.125, 0.85);
  }
  91% {
    transform: translate(em.convert(1px, $photon-size), 0) scale(1.125, 0.85);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes photon-trail-idle {
  0% {
    transform: scale(1, 1);
  }
  90% {
    transform: scale(1.5, 0.5);
  }
  91% {
    transform: scale(1.5, 0.5);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes photon-particle-out {
  0% {
    inline-size: 1em;
    opacity: 1;
  }
  100% {
    inline-size: 1em;
    opacity: 0;
  }
}

@keyframes photon-trail-out {
  0% {
    inline-size: $photon-size * 10;
    opacity: 1;
  }
  50% {
    inline-size: $photon-size * 5;
  }
  100% {
    inline-size: 1em;
    opacity: 0;
  }
}
