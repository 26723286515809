/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Switch
 * Type: Component
**/

/*******************************************************************************
 * Utilities
*******************************************************************************/

@use "../settings/colors";
@use "@lunaweb/vega-reactor-scss/src/tools/animation";
@use "@lunaweb/vega-reactor-scss/src/tools/hide";

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-switch {
  position: relative;
  appearance: none;
  padding: 0.25rem;
  color: colors.get("foreground");
  background-color: colors.get("background");
  border: 0;
  border-radius: 1rem;
  line-height: 0;
  cursor: pointer;
  @include animation.transition(color background-color);

  &::before {
    content: "";
    position: absolute;
    inset: -0.125rem;
    border-radius: 50%;
    background-color: colors.get("foreground");
    opacity: 0.25;
    transform: scale(0);
    @include animation.transition(transform);
  }

  &:hover {
    &::before {
      transform: scale(1);
    }
  }
}

  .c-switch__label {
    @include hide.visually;
  }

  .c-switch__icon {
    position: relative;
  }

/*******************************************************************************
 * States
*******************************************************************************/

.c-switch[aria-pressed="true"] {
  color: colors.get("background");
  background-color: colors.get("foreground");

  &::before {
    opacity: 1;
    transform: scale(0.75);
  }

  &:hover {
    &::before {
      transform: scale(1);
    }
  }

  .c-switch__icon--inactive {
    display: none;
  }
}

.c-switch[aria-pressed="false"] {
  .c-switch__icon--active {
    display: none;
  }
}

/*******************************************************************************
 * Variants
*******************************************************************************/

.c-switch--theme {
  &[aria-pressed="true"] {
    color: colors.get("background");
    background-color: colors.get("foreground");
  }

  &[aria-pressed="false"] {
    color: colors.get("background");
    background-color: colors.get("foreground");

    &::before {
      opacity: 1;
      transform: scale(0.75);
    }

    &:hover {
      &::before {
        transform: scale(1);
      }
    }
  }
}
