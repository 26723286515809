/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Animation
 * Type: Component
**/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.u-animation-container {
  perspective: 80rem;
}

.u-animation-item {
  @supports(animation-timeline: view(block)) {
    animation-name: reveal;
    animation-fill-mode: both;
    animation-timeline: view(block);
    animation-range: entry exit;
    animation-duration: calc(var(--animations-enabled) * 1ms); /* Firefox requires this to apply the animation */
  }
}

@keyframes reveal {
  0% {
    transform-origin: top center;
    transform: translateY(calc(var(--animations-enabled) * 1rem)) rotateX(calc(var(--animations-enabled) * -0.1turn)) scale(calc(1 - var(--animations-enabled) * 0.01));
    opacity: calc(1 - var(--animations-enabled) * 1);
  }
  25% {
    transform-origin: top center;
    transform: translateY(0) rotateX(0) scale(1);
    opacity: calc(1 - var(--animations-enabled) * 0.45);
  }
  40% {
    transform-origin: top center;
    transform: translateY(0) rotateX(0) scale(1);
    opacity: calc(1 - var(--animations-enabled) * 0);
  }
  75% {
    transform-origin: bottom center;
    transform: translateY(0) rotateX(0) scale(1);
    opacity: calc(1 - var(--animations-enabled) * 0);
  }
  85% {
    transform-origin: bottom center;
    transform: translateY(0) rotateX(0) scale(1);
    opacity: calc(1 - var(--animations-enabled) * 0.45);
  }
  100% {
    transform-origin: bottom center;
    transform: translateY(calc(var(--animations-enabled) * -1rem)) rotateX(calc(var(--animations-enabled) * 0.1turn)) scale(calc(1 - var(--animations-enabled) * 0.01));
    opacity: calc(1 - var(--animations-enabled) * 1);
  }
}

/*******************************************************************************
 * Print
*******************************************************************************/

@media print {
  .u-animation-item {
    @supports(animation-timeline: view(block)) {
      animation: none;
    }
  }
}
