/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Fonts
 * Type: Generic
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "../settings/fonts" as font;
@use "../tools/fonts";

/*******************************************************************************
 * Default styles
*******************************************************************************/

@if font.$use-vfont {
    @font-face {
      font-display: swap;
      font-family: "Syne Variable";
      font-weight: 400 800;
      src: url("#{font.$folder}Syne-subset.woff");
      src:
        url("#{font.$folder}Syne-subset.woff2")  format("woff2"),
        url("#{font.$folder}Syne-subset.woff")   format("woff");
    }
}
