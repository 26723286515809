/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Document
 * Type: Elements
 *
 * Description: Simple page-level setup.
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass:map";
@use "sass:math";
@use "sass-rem/rem";
@use "../settings/colors";
@use "../settings/fonts";
@use "@lunaweb/vega-reactor-scss/src/tools/animation";

/*******************************************************************************
 * Default styles
*******************************************************************************/

/**
 * 1. Set the default `font-size` and `line-height` for the entire project,
 *    sourced from our default variables. The `font-size` is calculated to exist
 *    in ems, the `line-height` is calculated to exist unitlessly.
 * 2. Force scrollbars to always be visible to prevent awkward ‘jumps’ when
 *    navigating between pages that do/do not have enough content to produce
 *    scrollbars naturally.
 * 3. Ensure the page always fills at least the entire height of the viewport.
**/
html {
  display: flex;
  min-block-size: 100%; /* [3] */
  overflow-y: scroll; /* [2] */
  font-family: fonts.$default;
  font-size: 100%; /* [1] */
  font-size: calc(100% + 1vw); /* [1] */
  line-height: 1.5; /* [1] */
  color: colors.get("foreground");
  background: colors.get("background") none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth; /* [4] */

  &.is-dark {
    --color-foreground: #{ map.get(colors.$all, "foreground") };
    --color-background: #{ map.get(colors.$all, "background") };
  }

  &.is-light {
    --color-foreground: #{ map.get(colors.$all, "foreground-revert") };
    --color-background: #{ map.get(colors.$all, "background-revert") };
  }
}

body {
  inline-size: 100%;
  margin: 0;
  color: colors.get("foreground");
  background: colors.get("background") none;
}

/*******************************************************************************
 * Print styles
*******************************************************************************/

@media print {
  @page {
    margin: 1.5cm;
  }

  html,
  body {
    block-size: auto;

    --color-foreground: black;
    --color-background: white;
  }

  html {
    @include rem.baseline(80%);
  }
}
