/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Typography
 * Type: Generic
**/

/*******************************************************************************
 * Utilities
*******************************************************************************/

@use "../settings/colors";
@use "@lunaweb/vega-reactor-scss/src/tools/animation";

/*******************************************************************************
 * Default styles
*******************************************************************************/

* {
  text-decoration-thickness: 0.05em;
  text-underline-position: from-font;
  text-underline-offset: 0.05em;

  -webkit-overflow-scrolling: touch;

  /* Firefox */
  scrollbar-width: auto;
  scrollbar-color: colors.get("foreground") colors.get("background");
  @include animation.transition(scrollbar-color);
}

::selection {
  color: colors.get("background");
  background-color: colors.get("foreground");
}
