/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Icon
 * Type: Component
**/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-icon {
  inline-size: 0.75em;
  inline-size: 1cap;
  block-size: 0.75em;
  block-size: 1cap;
}
