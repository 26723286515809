/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Link
 * Type: Component
**/

/*******************************************************************************
 * Utilities
*******************************************************************************/

@use "sass-em/em";
@use "sass-rem/rem";
@use "../settings/fonts";

/*******************************************************************************
 * Variables
*******************************************************************************/

$link-font-size: fonts.$base;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-link {
  position: relative;
  display: inline-block;
  padding: 0.5em 0;
  font-family: fonts.$primary;
  // font-size: rem.convert($link-font-size);
  font-weight: fonts.$medium;
  text-decoration-line: none;
  color: inherit;
}

  .c-link__photon {
    position: absolute;
    inset-inline: em.convert(-1px, $link-font-size);

    &:first-child {
      inset-block-end: 0.25em;
    }

    &:last-child {
      inset-block-start: 0.25em;
    }
  }

  .c-link__label {}

.c-link:not(.is-mounted):not(:hover) {
  .c-link__photon { display: none; }
}
