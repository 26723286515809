/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Root
 * Type: Generic
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass:map";
@use "sass-rem/rem";
@use "../settings/global";
@use "../settings/colors";

/*******************************************************************************
 * Default styles
*******************************************************************************/

:root {
  --pointer-enabled: 1;
  --animations-enabled: 0;
  --dark-theme: 1;

  @each $name, $value in colors.$all {
    --color-#{ $name }: #{ $value };
  }

  @media (hover: none) {
    --pointer-enabled: 0;
  }
}

@-ms-viewport { inline-size: device-width; }
