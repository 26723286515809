/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: System
 * Type: Component
**/

/*******************************************************************************
 * Utilities
*******************************************************************************/

@use "sass-rem/rem";
@use "../settings/colors";
@use "@lunaweb/vega-reactor-scss/src/tools/animation";

/*******************************************************************************
 * Variables
*******************************************************************************/

$revolution-duration: 12s;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-system {
  --system-distance: 0;
  --system-angle: -45deg;

  position: relative;
  display: inline-block;
  inline-size: 1em;
  block-size: 1em;
  padding: 0.3em;
  line-height: 0;
  overflow: hidden;
}

  .c-system__main,
  .c-system__satellite {
    position: absolute;
    overflow: hidden;
    border-radius: 50%;
  }

    .c-system__sphere {
      position: absolute;
      inline-size: 100%;
      block-size: 100%;
      border-radius: 50%;
      background-color: currentcolor;
      background: linear-gradient(0.5turn, currentcolor 0%, currentcolor 50%, transparent 50%, transparent 100%);
      transform: rotate3d(0, 0, 1, calc(var(--system-angle) * var(--animations-enabled)));
      will-change: transform;

      &::after {
        content: "";
        position: absolute;
        inset: 0;
        display: block;
        inline-size: 100%;
        block-size: 100%;
        border-radius: 50%;
        background-color: currentcolor;
        transform: rotate3d(1, 0, 0, calc(var(--system-distance) * var(--animations-enabled)));
        transition: transform 100ms ease-out;
        will-change: transform;

        .c-system:not(.had-reveal) & {
          animation: system-sphere-reveal calc(4s * var(--animations-enabled)) linear;
        }
      }
    }

  .c-system__main {
    z-index: 1;
    inline-size: 0.4em;
    block-size: 0.4em;
  }

  .c-system__svg {
    position: absolute;
    inset: 0;
    inline-size: 1em;
    block-size: 1em;
    transform: rotate(-0.125turn);
  }

    .c-system__circle {
      stroke-dashoffset: 0;
      animation: system-orbit-reveal calc($revolution-duration * var(--animations-enabled)) linear;
    }

  .c-system::before,
  .c-system__orbit {
    position: absolute;
    inset: 0.075em;
    inline-size: 0.85em;
    block-size: 0.85em;
  }

  .c-system__orbit {
    z-index: 1;
    animation: system-orbit calc($revolution-duration * var(--animations-enabled)) infinite linear;
    will-change: transform;
  }

    .c-system__satellite {
      inset-inline-end: 0.078em;
      inset-block-start: 0.078em;
      inline-size: 0.1em;
      block-size: 0.1em;
      background-color: colors.get("background");
      animation: system-orbit calc($revolution-duration * var(--animations-enabled)) infinite linear reverse;
    }

.c-system.is-idle {
  .c-system__sphere {
    animation: system-idle-angle calc(39s * var(--animations-enabled)) infinite linear;

    &::after {
      transform: rotate3d(1, 0, 0, 0deg);
      animation: system-idle-distance calc(27s * var(--animations-enabled)) infinite ease-in-out;
    }
  }
}

@keyframes system-idle-angle {
  0% {
    transform: rotate3d(0, 0, 1, 135deg);
  }
  100% {
    transform: rotate3d(0, 0, 1, -225deg);
  }
}

@keyframes system-idle-distance {
  0% {
    transform: rotate3d(1, 0, 0, 0deg);
  }
  50% {
    transform: rotate3d(1, 0, 0, 70deg);
  }
  100% {
    transform: rotate3d(1, 0, 0, 0deg);
  }
}

@keyframes system-orbit {
  from {
    transform: rotate3d(0, 0, 1, 0turn);
  }
  to {
    transform: rotate3d(0, 0, 1, 1turn);
  }
}

@keyframes system-sphere-reveal {
  0% {
    background-color: colors.get("background");
    transform: scaleY(-1);
  }
  15% {
    background-color: colors.get("background");
    transform: scaleY(-0.98);
  }
  60% {
    background-color: colors.get("background");
    transform: scaleY(0);
  }
  61% {
    background-color: colors.get("foreground");
    transform: scaleY(0);
  }
  100% {
    background-color: colors.get("foreground");
    transform: scaleY(1);
  }
}

@keyframes system-orbit-reveal {
  0% {
    stroke-dashoffset: 101;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

/*******************************************************************************
 * Print
*******************************************************************************/

@media print {
  .c-system {
    &::before {
      border-width: 0.125rem;
    }

    &::after {
      display: none;
    }
  }

    .c-system__circle,
    .c-system__orbit,
    .c-system__satellite,
    .c-system__sphere {
      animation: none !important;
    }

    .c-system__sphere {
      border: 0.125rem solid;
      background-color: currentcolor;

      &::after {
        display: none !important;
      }
    }
}
