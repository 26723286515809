/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Header
 * Type: Component
**/

/*******************************************************************************
 * Utilities
*******************************************************************************/

@use "../settings/colors";
@use "@lunaweb/vega-reactor-scss/src/tools/animation";

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-header {
  display: flex;
  justify-content: flex-end;
  inline-size: 100%;
  padding: 0.5rem;

  &::before {
    content: "";
    position: absolute;
    inset-block-start: 0;
    inset-inline: 0;
    z-index: -1;
    block-size: 150%;
    pointer-events: none;
    // background: linear-gradient(0.5turn, colors.get("background"), transparent);
    // @include animation.transition(color background-color);
  }
}

  .c-header__controls {
    display: flex;
    gap: 0.5rem;
    justify-self: flex-end;
  }

    .c-header__control {
      font-size: 1.5rem;

      &:not(.is-mounted) {
        display: none;
      }
    }

/*******************************************************************************
 * Print
*******************************************************************************/

@media print {
  .c-header {
    display: none;
  }
}
