/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Typography
 * Type: Element
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass-rem/rem";
@use "../settings/fonts";
@use "../tools/typography" as typo;
@use "@lunaweb/vega-reactor-scss/src/tools/animation";

/*******************************************************************************
 * Default styles
*******************************************************************************/

a {
  @include animation.transition(text-decoration-color);
  text-decoration-line: underline;
  color: inherit;
  border-radius: 0.125em;

  &:hover {
    text-decoration-color: transparent;
  }
}

strong {
  font-weight: fonts.$bold;
}

h1 {
  @include typo.h1;
}

h2 {
  @include typo.h2;
}

h3 {
  @include typo.h3;
}

h4 {
  @include typo.h4;
}

h5 {
  @include typo.h5;
}

h6 {
  @include typo.h6;
}

/*******************************************************************************
 * Print
*******************************************************************************/

@media print {
  a {
    text-decoration: none;
  }

  p {
    orphans: 3;
    widows: 3;
  }
}
