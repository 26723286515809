/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Page
 * Type: Object
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass-rem/rem";
@use "../settings/spacings";

/*******************************************************************************
 * Default styles
*******************************************************************************/

.o-page {
  display: flex;
  flex-direction: column;
  min-block-size: 100vh;

  > * {
    flex: 0 0 auto;
    inline-size: 100%;
  }
}

  .o-page__header {
    position: fixed;
    inset-block-start: 0;
    z-index: 30;
  }

  .o-page__main {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    &--middle {
      justify-content: center;
    }
  }


  .o-page__footer {
    position: relative;
    z-index: 10;
  }

/*******************************************************************************
 * Print
*******************************************************************************/

@media print {
  .o-page__header,
  .o-page__skiplinks,
  .o-page__footer {
    display: none;
  }
}
