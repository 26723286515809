/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Header
 * Type: Component
**/

/*******************************************************************************
 * Utilities
*******************************************************************************/

@use "../settings/colors";

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-footer {
  padding: 1em;
  font-size: 0.85rem;
}

  .c-footer__items {
    display: flex;
    justify-content: center;
    gap: 2rem;
    list-style: none;
    margin: 0;
    padding: 0;
  }

    .c-footer__item {}

      .c-footer__link {
        font-size: 1em;
      }
