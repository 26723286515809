/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Fonts
 * Type: Tool
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass:math";
@use "sass-rem/rem";
@use "../settings/fonts";
@use "../tools/global";

/*******************************************************************************
* Default styles
*******************************************************************************/

// Titile

// Generate overflowing link with pseudo-element
@mixin title($root) {
  .c-#{$root}__mainlink {
    font-weight: inherit;
    text-decoration-color: transparent;
    color: inherit;

    &::before {
      content: "";
      position: absolute;
      inset: 0;
    }

    &:hover {
      text-decoration-line: underline;
      text-decoration-color: currentcolor;
      color: inherit;
    }
  }
}

// Headings

@mixin h1 {
  font-size: 1.75rem;
  font-family: fonts.$primary;
  font-weight: 700;
  line-height: 1.2;
}

@mixin h2 {
  font-family: fonts.$primary;
  font-size: 1.5rem;
  line-height: 1.2;
}

@mixin h3 {
  font-family: fonts.$primary;
  font-size: 1.25rem;
  line-height: 1.2;
}

@mixin h4 {
  font-family: fonts.$primary;
  font-size: 1.15rem;
  line-height: 1.2;
}

@mixin h5 {
  font-family: fonts.$primary;
  font-size: 1.1rem;
  line-height: 1.2;
}

@mixin h6 {
  font-family: fonts.$primary;
  font-size: 1rem;
  line-height: 1.2;
}

@mixin text {
  font-size: rem.convert(fonts.$base);
  line-height: inherit;
}

@mixin text-small {
  font-size: rem.convert(fonts.$small);
  line-height: inherit;
}

@mixin text-large {
  @include global.fluid-size(fonts.$base, 20px);
  line-height: inherit;
}
