/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Fonts
 * Type: Setting
**/

/*******************************************************************************
 * Config
*******************************************************************************/

$use-vfont: true;

/*******************************************************************************
 * @font-face helpers
*******************************************************************************/

$folder: "../fonts/";

/*******************************************************************************
 * Families
*******************************************************************************/

$default: Verdana;
$primary: "Syne Variable", monospace;

/*******************************************************************************
 * Types
*******************************************************************************/

$small: 14px;
$base: 16px;
$large: 20px;

/*******************************************************************************
 * Weights
*******************************************************************************/

// $thin: 100;
// $extralight: 200;
// $light: 300;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
// $extrabold: 800;
// $black: 900;
